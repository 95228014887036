<template>
    <div class="tmpl-container">
        <div class="product-img">
            <img :src="randomData.img" class="img">
        </div>
        <div class="info-container">
            <div class="info"><a :href="'/d/' + randomData.title" class="t-image">
                    <h3 class="info-name">{{randomData.name}}</h3>
                </a> <span class="info-desc">{{ randomData.desc }}</span></div>
            <div class="category-learn-more-container">
                <span class="info-category">{{randomData.category}}</span>
                <div class="info-button">
                    <a :href="'/d/' + randomData.title" class="c-button-container t-image"
                        style="border-radius:8px;font-size:16px;background-color:#434343;" >Learn
                        More</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { randomFilterData } from '@/assets/vendor/js/utail';
export default {
    data() {
        return {
            randomData: {}
        }
    },
    async created() {
        this.appData = this.$store.state.appData;
        this.randomData = randomFilterData(this.appData);
    }
}
</script>

<style lang="scss" scoped>
.tmpl-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%
}

.tmpl-container .product-img {
    width: 50%;
    border: 1px solid #eaeaea;
    position: relative;
    padding-bottom: 29.3%;
    overflow: hidden
}

.tmpl-container .product-img .img {
    width: 100%;
    height: 100%;
    position: absolute
}

.tmpl-container .info-container {
    width: calc(50% - 40px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column
}

.tmpl-container .info-container .info {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start
}

.tmpl-container .info-container .info .info-name {
    position: relative;
    z-index: 10;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    vertical-align: top;
    font-weight: 700;
    color: #262833;
    display: inline-block;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.tmpl-container .info-container .info .info-name:after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 25px;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: #d6d6d6
}

.tmpl-container .info-container .info .info-desc {
    font-size: 20px;
    color: #666;
    padding-top: 50px;
    height: 170px;
    overflow: hidden;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical
}

.tmpl-container .info-container .category-learn-more-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px
}

.tmpl-container .info-container .category-learn-more-container .info-category {
    font-size: 20px;
    color: #000;
    line-height: 20px
}

.tmpl-container .info-container .category-learn-more-container .info-button {
    width: 200px;
    height: 40px
}

.tmpl-container .info-container .info-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    background-color: #515151;
    border-radius: 50%
}

.c-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 100%;
    height: 100%;
    box-shadow: 0 6px 12px rgba(134, 140, 150, .65)
}

@media screen and (max-width: 1200px) {
    .tmpl-container .info-container .info .info-name {
        font-size: 36px;
        line-height: 46px
    }

    .tmpl-container .info-container .info .info-name:after {
        top: 23px;
        height: 46px
    }

    .tmpl-container .info-container .info .info-desc {
        font-size: 16px;
        line-height: 26px;
        padding: 30px 0;
        height: 180px;
        -webkit-line-clamp: 6
    }
}

@media screen and (max-width: 1000px) {
    .tmpl-container .info-container .info .info-name {
        font-size: 30px;
        line-height: 40px
    }

    .tmpl-container .info-container .info .info-name:after {
        top: 15px;
        height: 40px
    }

    .tmpl-container .info-container .info .info-desc {
        font-size: 12px;
        line-height: 24px;
        padding: 30px 0;
        height: 100px;
        -webkit-line-clamp: 3
    }

    .tmpl-container .info-container .category-learn-more-container {
        padding-top: 10px
    }

    .tmpl-container .info-container .category-learn-more-container .info-button {
        height: 36px
    }
}

@media screen and (max-width: 768px) {
    .tmpl-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        gap: 20px
    }

    .tmpl-container .product-img {
        width: 100%;
        background-color: grey;
        position: relative;
        padding-bottom: 58.6%;
        overflow: hidden
    }

    .tmpl-container .info-container {
        width: 100%
    }
}
</style>