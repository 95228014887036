<template>
    <div class="top-rec-list">
        <div class="top-rec-item" v-for="(item, index) in data" :key="index">
            <div class="tmpl-container">
                <div class="product-img"><img :src="item.img" class="img" :data-src="item.img" lazy="loaded"></div>
                <div class="info-container">
                    <div class="info"><a :href="'/editors_choice/d/' + item.id" class="info-href t-image">
                            <h3 class="info-name">{{ item.name }}</h3>
                        </a> <span class="info-desc">{{ item.desc }}</span></div>
                    <div class="category-learn-more-container">
                        <div class="info-button"><a :href="'/editors_choice/d/' + item.id"
                                class="c-button-container t-image"
                                style="border-radius:8px;font-size:16px;background-color:#434343;">Learn More</a></div>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div class="article-list" style="margin-top:4px;">
        <div class="article-item" v-for="(item, index) in data" :key="index">
            <a :href="'/editors_choice/d/' + item.id" class="item-img t-image">
                <img :src="item.img" class="img" :data-src="item.img" lazy="loaded">
                <div class="info-container">
                    <h2 class="info-title">{{ item.name }}</h2>
                </div>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    props: ['data'],
    mounted() {
        // console.log("傳達過來的data",this.data);
    }
}
</script>

<style scoped lang="scss">
.top-rec-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 10px
}

.top-rec-list .top-rec-item {
    width: 100%
}

.top-rec-list .top-rec-item .tmpl-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%
}

.top-rec-list .top-rec-item .tmpl-container .product-img {
    width: 50%;
    border: 1px solid #eaeaea;
    position: relative;
    padding-bottom: 29.3%;
    overflow: hidden
}

.top-rec-list .top-rec-item .tmpl-container .product-img .img {
    width: 100%;
    height: 100%;
    position: absolute
}

.top-rec-list .top-rec-item .tmpl-container .info-container {
    width: calc(50% - 40px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column
}

.top-rec-list .top-rec-item .tmpl-container .info-container .info {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start
}

.top-rec-list .top-rec-item .tmpl-container .info-container .info .info-href {
    width: 100%
}

.top-rec-list .top-rec-item .tmpl-container .info-container .info .info-href .info-name {
    width: 100%;
    position: relative;
    z-index: 10;
    font-size: 36px;
    line-height: 50px;
    vertical-align: top;
    font-weight: 700;
    color: #262833;
    display: inline-block
}

.top-rec-list .top-rec-item .tmpl-container .info-container .info .info-desc {
    font-size: 18px;
    color: #666;
    padding-top: 50px;
    height: 160px;
    overflow: hidden;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical
}

.top-rec-list .top-rec-item .tmpl-container .info-container .category-learn-more-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 20px
}

.top-rec-list .top-rec-item .tmpl-container .info-container .category-learn-more-container .info-category {
    font-size: 20px;
    color: #000;
    line-height: 20px
}

.top-rec-list .top-rec-item .tmpl-container .info-container .category-learn-more-container .info-button {
    width: 200px;
    height: 40px
}

@media screen and (max-width:1200px) {
    .top-rec-list {
        display: none;
    }

    .top-rec-list .top-rec-item .tmpl-container .info-container .info .info-name {
        font-size: 36px;
        line-height: 46px
    }

    .top-rec-list .top-rec-item .tmpl-container .info-container .info .info-name:after {
        top: 23px;
        height: 46px
    }

    .top-rec-list .top-rec-item .tmpl-container .info-container .info .info-desc {
        font-size: 16px;
        line-height: 26px;
        padding: 30px 0;
        height: 180px;
        -webkit-line-clamp: 6
    }
}

@media screen and (max-width:1000px) {
    .top-rec-list .top-rec-item .tmpl-container .info-container .info .info-name {
        font-size: 30px;
        line-height: 40px
    }

    .top-rec-list .top-rec-item .tmpl-container .info-container .info .info-name:after {
        top: 15px;
        height: 40px
    }

    .top-rec-list .top-rec-item .tmpl-container .info-container .info .info-desc {
        font-size: 12px;
        line-height: 24px;
        padding: 30px 0;
        height: 100px;
        -webkit-line-clamp: 3
    }

    .top-rec-list .top-rec-item .tmpl-container .info-container .category-learn-more-container {
        padding-top: 10px
    }

    .top-rec-list .top-rec-item .tmpl-container .info-container .category-learn-more-container .info-button {
        height: 36px
    }
}

@media screen and (max-width:768px) {
    .top-rec-list .top-rec-item .tmpl-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        gap: 20px
    }

    .top-rec-list .top-rec-item .tmpl-container .product-img {
        width: 100%;
        background-color: grey;
        position: relative;
        padding-bottom: 58.6%;
        overflow: hidden
    }

    .top-rec-list .top-rec-item .tmpl-container .info-container {
        width: 100%
    }
}


.article-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px
}

@media screen and (min-width:1200px) {
    .article-list {
        display: none;
    }
}

.article-list .article-item {
    flex: 0 0 calc(50% - 10px)
}

@media screen and (max-width:480px) {
    .article-list .article-item {
        flex: 0 0 100%
    }
}

.article-list .article-item .item-img {
    display: block;
    position: relative;
    width: 100%;
    padding-bottom: 58.63%
}

.article-list .article-item .item-img .img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    -o-object-fit: cover;
    object-fit: cover
}

.article-list .article-item .item-img .info-container {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .4)
}

.article-list .article-item .item-img .info-container .info-title {
    width: 80%;
    text-align: center;
    padding: 10px;
    border: 2px solid #fff;
    border-radius: 10px;
    color: #fff;
    font-size: 20px;
    font-weight: 700
}
</style>