// utils.js
export function categoryForData(appData, ct) {
  return appData
    .filter(app => app.cg === ct)
    .sort((a, b) => {
      if (b.star === a.star) {
        const exposureA = parseInt(a.exposure.replace(/\D/g, ''));
        const exposureB = parseInt(b.exposure.replace(/\D/g, ''));
        return exposureB - exposureA;
      }
      return b.star - a.star;
    }).map(item => {
      let title = item.name || ''; // 如果 title 为空或 undefined，则赋值为空字符串
      const processedTitle = title
        .replace(/\s+/g, '_')        // 将空格替换成下划线
        .replace(/[^a-zA-Z_]/g, '_'); // 过滤掉非字母和下划线的字符
      return {
        ...item,
        title: processedTitle // 更新后的 title
      }
    });
}
export function filterAndSortApps(appData, category) {
  return appData
    .filter(app => app.category === category)
    .sort((a, b) => {
      if (b.star === a.star) {
        const exposureA = parseInt(a.exposure.replace(/\D/g, ''));
        const exposureB = parseInt(b.exposure.replace(/\D/g, ''));
        return exposureB - exposureA;
      }
      return b.star - a.star;
    }).map(item => {
      let title = item.name || ''; // 如果 title 为空或 undefined，则赋值为空字符串
      const processedTitle = title
        .replace(/\s+/g, '_')        // 将空格替换成下划线
        .replace(/[^a-zA-Z_]/g, '_'); // 过滤掉非字母和下划线的字符
      return {
        ...item,
        title: processedTitle // 更新后的 title
      }
    });
}
export function DataDetail(appData, id) {
  return appData
    .filter(app => app.id === id)
    .map(item => {
      let title = item.name || ''; // 如果 title 为空或 undefined，则赋值为空字符串
      const processedTitle = title
        .replace(/\s+/g, '_')        // 将空格替换成下划线
        .replace(/[^a-zA-Z_]/g, '_'); // 过滤掉非字母和下划线的字符
      return {
        ...item,
        title: processedTitle // 更新后的 title
      }
    });
}
export function DataDetailByName(appData, name) {
  return appData
    .filter(app => app.name.replace(/\s+/g, '_').replace(/[^a-zA-Z_]/g, '_')
     === name)
    .map(item => {
      let title = item.name || ''; // 如果 title 为空或 undefined，则赋值为空字符串
      const processedTitle = title
        .replace(/\s+/g, '_')        // 将空格替换成下划线
        .replace(/[^a-zA-Z_]/g, '_'); // 过滤掉非字母和下划线的字符
      return {
        ...item,
        title: processedTitle // 更新后的 title
      }
    });
}
export function SortApps(appData) {
  return appData
    .sort((a, b) => {
      if (b.star === a.star) {
        const exposureA = parseInt(a.exposure.replace(/\D/g, ''));
        const exposureB = parseInt(b.exposure.replace(/\D/g, ''));
        return exposureB - exposureA;
      }
      return b.star - a.star;
    }).map(item => {
      let title = item.name || ''; // 如果 title 为空或 undefined，则赋值为空字符串
      const processedTitle = title
        .replace(/\s+/g, '_')        // 将空格替换成下划线
        .replace(/[^a-zA-Z_]/g, '_'); // 过滤掉非字母和下划线的字符
      return {
        ...item,
        title: processedTitle // 更新后的 title
      }
    });
}
export function filterPage(appData, category, start, end) {
  const sortedApps = filterAndSortApps(appData, category);
  if (end !== undefined) {
    return sortedApps.slice(start, end);
  }
  else {
    return sortedApps.slice(start)
  }
}
export function randomFilterData(appData) {
  const sortedApps = SortApps(appData);
  const randomApp = getRandomApp(sortedApps);
  return randomApp;
}
export function randomFilterDatas(appData, count) {
  const sortedApps = SortApps(appData);
  if (sortedApps.length < count) {    
    return sortedApps;
  }
  const selectedIndices = new Set();
  while (selectedIndices.size < count) {
    const randomIndex = Math.floor(Math.random() * sortedApps.length);
    selectedIndices.add(randomIndex);
  }
  const randomApps = Array.from(selectedIndices).map(index => sortedApps[index]);
  return randomApps;
}
function getRandomApp(apps) {
  const randomIndex = Math.floor(Math.random() * apps.length);
  return apps[randomIndex];
}
