<template>
    <div :class="['ad-block', 'ad-container', className]">
        
    </div>
</template>

<script>
export default{
    props:{
        className:String
    }
}
</script>

<style lang="scss" scoped >
.ad-container{
    // min-height: 300px;
}
</style>