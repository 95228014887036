<template>
    <div class="hot-ai-tool-container" style="height: auto !important;">
        <!---->
        <div class="ai-tools-container" style="height: auto !important;">
            <webBanner v-if="device === 'desktop'" />
            <WebBannerH5 v-else-if="device === 'mobile'" />
            <WebBannerH5 v-else-if="device === 'tablet'" />
            <MainAd className="my-4" />
            <HomeCategorySection category="Free" v-if="device === 'desktop'" />
            <HomeSectionH5 category="Free" v-if="device === 'mobile'|| device === 'tablet'" />
            <MainAd className="my-4" />
            <HomeCategorySection category="Other" v-if="device === 'desktop'" />
            <HomeSectionH5 category="Other" v-if="device === 'mobile'|| device === 'tablet'" />
            <HomeCategorySection category="Writing" v-if="device === 'desktop'" />
            <HomeSectionH5 category="Writing" v-if="device === 'mobile'|| device === 'tablet'" />
            <MainAd className="my-4" />
            <HomeCategorySection category="Design" v-if="device === 'desktop'" />
            <HomeSectionH5 category="Design" v-if="device === 'mobile'|| device === 'tablet'" />
            <HomeCategorySection category="Coding" v-if="device === 'desktop'" />
            <HomeSectionH5 category="Coding" v-if="device === 'mobile'|| device === 'tablet'" />
            <HomeCategorySection category="Chatbot" v-if="device === 'desktop'" />
            <HomeSectionH5 category="Chatbot" v-if="device === 'mobile'|| device === 'tablet'" />
        </div>
    </div>
</template>

<script>
import webBanner from '@/components/webBanner.vue';
import WebBannerH5 from '@/components/WebBannerH5.vue';
import HomeCategorySection from './HomeCategorySection.vue';
import HomeSectionH5 from './HomeCategorySectionH5.vue';
import MainAd from '@/components/MainAd.vue';

export default {
    data() {
        return {
            device: ''
        }
    },
    components: {
        webBanner,
        WebBannerH5,
        HomeCategorySection,
        MainAd,
        HomeSectionH5
    },
    mounted() {
        this.updateDeviceType();
        window.addEventListener('resize', this.updateDeviceType);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateDeviceType);
    },
    methods: {
        updateDeviceType() {
            const width = window.innerWidth;
            if (width >= 1200) {
                this.device = 'desktop';
            } else if (width >= 768 && width < 1200) {
                this.device = 'tablet';
            } else {
                this.device = 'mobile';
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.hot-ai-tool-container {
    margin: 0 auto;
    padding: 0
}

.ai-tools-container {
    margin: 0 auto;
    padding: 40px 20px;
    max-width: 1200px
}
</style>