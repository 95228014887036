<template>
    <div class="free-container">
        <div class="category-title">
            <h2 class="category-title-info">{{ category }}</h2>
            <div class="learn-more-btn">
                <a :href="'/c/'+category" class="c-button-container gt-400"
                    style="border-radius: 8px; font-size: 14px; background-color: rgb(0, 136, 255);">Learn More</a>
                <a :href="'/c/'+category" class="c-button-container lt-400"
                    style="border-radius: 8px; font-size: 8px; background-color: rgb(0, 136, 255);">Learn More</a>
            </div>
        </div>
        <div class="category-list">
            <div class="category-item" v-for="(item, index) in top4" :key="index">
                <div class="tmpl-container"><a :href="'/d/' + item.title" class="t-image">
                        <div class="product-img"><img :src="item.img" class="img" :data-src="item.img" lazy="loaded">
                        </div>
                        <div class="info-container">
                            <div class="info"><span class="info-category">{{ item.category }}</span>
                                <h3 class="info-name">{{ item.name }}</h3>
                            </div>
                            <div class="info-button"><a :href="'/d/' + item.title" class="c-button-container t-image"
                                    style="border-radius: 20px; font-size: 10px; background-color: rgb(0, 136, 255);">Open</a>
                            </div>
                        </div>
                    </a></div>
            </div>
        </div>
    </div>
</template>
<script>
import { filterAndSortApps } from '@/assets/vendor/js/utail';
export default {
    data() {
        return {
            appData: [],
            top4: []
        }
    },
    props: ['category'],
    async created() {
        this.appData = this.$store.state.appData;
        this.filteredApps = filterAndSortApps(this.appData, this.category); // 传入 appData 和 category
        this.top4 = this.filteredApps.slice(0, 4);
    }
}
</script>
<style lang="scss" scoped>
.free-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px
}

.free-container .category-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    row-gap: 20px
}

.free-container .category-list .category-item {
    width: calc(50% - 5px)
}

@media screen and (max-width:400px) {
    .free-container .category-list .category-item {
        width: 100%
    }
}

/*purgecss end ignore*/
/*purgecss start ignore*/
.category-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.category-title .category-title-info {
    color: #262833;
    font-size: 24px;
    font-weight: 600
}

@media screen and (max-width:400px) {
    .category-title .category-title-info {
        font-size: 20px
    }
}

.category-title .learn-more-btn {
    width: 120px;
    height: 36px
}

.category-title .learn-more-btn .lt-400 {
    display: none
}

.category-title .learn-more-btn .gt-400 {
    display: flex
}

@media screen and (max-width:400px) {
    .category-title .learn-more-btn {
        width: 100px;
        height: 30px
    }

    .category-title .learn-more-btn .lt-400 {
        display: flex
    }

    .category-title .learn-more-btn .gt-400 {
        display: none
    }
}

/*purgecss end ignore*/
/*purgecss start ignore*/
.tmpl-container {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    border: 1px solid #eaeaea;
    border-radius: 10px
}

.tmpl-container .product-img {
    width: 100%;
    background-color: #f2f2f2;
    overflow: hidden;
    position: relative;
    padding: 20px 20px 78.7%
}

.tmpl-container .product-img .img {
    border-radius: 20px;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    position: absolute;
    overflow: hidden
}

.tmpl-container .info-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 10px
}

.tmpl-container .info-container .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px
}

.tmpl-container .info-container .info .info-category {
    font-size: 10px;
    line-height: 10px;
    color: #515151
}

.tmpl-container .info-container .info .info-name {
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
    color: #262833;
    max-width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.tmpl-container .info-container .info-button {
    width: 60px;
    height: 30px
}
</style>