import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import TopCharts from '../views/TopCharts.vue'
import AiTools from '../views/AITools.vue'
import AiApps from '@/views/AiApps.vue'
import EditorsChoice from '@/views/EditorsChoice.vue'
import ToPic from '@/views/ToPic.vue'

const routes = [
  {
    path: '/d/:name',
    name: 'appDetail',
    meta:{
      title:'Get AI Here',
    },
    component: ()=>import('../views/AppDetail.vue'),
    props: true // 允许通过路由参数传递给组件
  },
  {
    path: '/c/:category',
    name: 'category',
    meta:{
      title:'Get AI Here',
    },
    component: ()=>import('../views/Category.vue'),
    props: true 
  },
  {
    path: '/top_charts',
    name: 'topCharts',
    meta:{
      title:'Get AI Here',
    },
    component: TopCharts
  },
  {
    path: '/ai_tools',
    name: 'aiTools',
    meta:{
      title:'Get AI Here - The Complete Resource Of Artificial Intelligence Tools',
    },
    component: AiTools
  },
  {
    path: '/editors_choice',
    name: 'editorsChoice',
    meta:{
      title:'Get AI Here - The Complete Resource Of Artificial Intelligence Tools',
    },
    component: EditorsChoice
  },
  {
    path: '/editors_choice/d/:id',
    name: 'editorsChoiceDetail',
    meta:{
      title:'Get AI Here',
    },
    component: ()=>import('../views/EditorChoiceDetail.vue'),
    props: true
  },
  {
    path: '/topic',
    name: 'toPic',
    meta:{
      title:'Get AI Here - The Complete Resource Of Artificial Intelligence Tools',
    },
    component: ToPic
  },
  {
    path: '/ai_apps',
    name: 'aiApps',
    meta:{
      title:'Get AI Here - The Complete Resource Of Artificial Intelligence Tools',
    },
    component: AiApps
  },
  {
    path: '/',
    name: 'home',
    meta:{
      title:'Get AI Here - The Complete Resource Of Artificial Intelligence Tools',
    },
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {  
  if (to.meta.title) {
    const dynamicTitle = to.params.name
      ? `${to.params.name} - ${to.meta.title}`
      : to.meta.title
    document.title = dynamicTitle
  } else {
    document.title = '默认标题'
  }
  next()
});  

export default router
