<template>
  <div class="main-container">
    <div class="index-main-container"><!---->
      <div class="index-container">
        <div class="index-topbanner">
          <webBanner v-if="device === 'desktop'" />
          <WebBannerH5 v-else-if="device === 'mobile'" />
          <WebBannerH5 v-else-if="device === 'tablet'" />
        </div>
        <MainAd className="my-4" />
        <HomeSection category="Free" v-if="device === 'desktop'" />
        <HomeSectionH5 category="Free" v-if="device === 'mobile'|| device === 'tablet'" />
        <MainAd className="my-4" />
        <HomeSection category="Other" v-if="device === 'desktop'" />
        <HomeSectionH5 category="Other" v-if="device === 'mobile'|| device === 'tablet'" />
        <HomeSection category="Writing" v-if="device === 'desktop'" />
        <HomeSectionH5 category="Writing" v-if="device === 'mobile'|| device === 'tablet'" />
        <MainAd className="my-4" />
        <HomeSection category="Design" v-if="device === 'desktop'" />
        <HomeSectionH5 category="Design" v-if="device === 'mobile'|| device === 'tablet'" />
        <HomeSection category="Coding" v-if="device === 'desktop'" />
        <HomeSectionH5 category="Coding" v-if="device === 'mobile'|| device === 'tablet'" />
        <HomeSection category="Chatbot" v-if="device === 'desktop'" />
        <HomeSectionH5 category="Chatbot" v-if="device === 'mobile'|| device === 'tablet'" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import '@/assets/css/global.css'
import webBanner from '@/components/webBanner.vue'
import WebBannerH5 from '@/components/WebBannerH5.vue';
import webHeader from '@/components/webHeader.vue'
import webFooter from '@/components/WebFooter.vue'
import HomeSection from '@/views/HomeCategorySection.vue'
import MainAd from '@/components/MainAd.vue'
import HomeSectionH5 from './HomeCategorySectionH5.vue';

export default {
  name: 'home',
  data() {
    return {
      device: ''
    }
  },
  components: {
    webBanner,
    webHeader,
    webFooter,
    HomeSection,
    HomeSectionH5,
    MainAd,
    WebBannerH5
  },
  mounted() {
    this.updateDeviceType();
    window.addEventListener('resize', this.updateDeviceType);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateDeviceType);
  },
  methods: {
    updateDeviceType() {
      const width = window.innerWidth;
      if (width >= 1200) {
        this.device = 'desktop';
      } else if (width >= 768 && width < 1200) {
        this.device = 'tablet';
      } else {
        this.device = 'mobile';
      }
    }
  },
  async created() {
  }
}
</script>

<style scoped lang="scss">
.main-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0
}

.index-main-container {
  margin: 0 auto;
  padding: 0
}

.index-container {
  margin: 0 auto;
  padding: 40px 20px;
  max-width: 1200px
}
</style>
