<template>
    <footer class="footer">
        <a href="/contactus" class="footer-item">
            Contact us
        </a><a href="/privacy" class="footer-item">
            Privacy Policy
        </a><a href="/legalterms" class="footer-item">
            Legal Terms
        </a>
    </footer>
</template>
<script>

</script>
<style>
.footer {
    position: relative;
    height: 30px;
    background-color: #d6d6d6;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #515151;
    font-size: 16px;
    font-weight: 500
}

@media screen and (max-width: 768px) {
    .footer {
        font-size: 12px
    }
}

.footer .footer-item {
    margin: 0 20px
}

@media screen and (max-width: 768px) {
    .footer .footer-item {
        margin: 0 10px
    }
}

.footer .footer-item:hover {
    text-decoration: underline
}
</style>