<template>
    <div class="free-container">
        <div class="category-title">
            <h2 class="category-title-info">{{ category }}</h2>
            <div class="learn-more-btn">
                <a :href="'/c/' + category" class="c-button-container"
                    style="border-radius:8px;font-size:12px;background-color:#434343;">Learn More</a>
            </div>
        </div>
        <div class="category-list">
            <div class="category-item" v-for="(app, index) in top4" :key="index">
                <a :href="'/d/'+app.title" class="tmpl-container t-image">
                    <div class="product-img">
                        <img :src="app.img" class="img" :data-src="app.img" lazy="loaded">
                    </div>
                    <div class="info-container">
                        <div class="info">
                            <h3 class="info-name">{{ app.name }}</h3>
                            <div class="category-rating-container">
                                <span class="info-category">{{ category }}</span>
                                <div class="rating-container" data-v-0f7a6127="">
                                    <svg v-for="n in Math.floor(app.star)" :key="'full-' + n" aria-hidden="true"
                                        class="star-item svg-loader"
                                        style="width: 14px; height: 14px; fill: rgb(37, 41, 51);">
                                        <use xlink:href="#svg-full-star" style="color: rgb(37, 41, 51);"></use>
                                    </svg>
                                    <svg v-if="app.star % 1 > 0" aria-hidden="true" class="star-item svg-loader"
                                        style="width: 14px; height: 14px; fill: rgb(37, 41, 51);">
                                        <use xlink:href="#svg-half-star" style="color: rgb(37, 41, 51);"></use>
                                    </svg>
                                    <span class="rating-score" style="color:black;">{{ app.score }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="info-button"><svg data-v-098d38e6="" aria-hidden="true" class="svg-loader"
                                style="width: 30px; height: 30px; fill: rgb(255, 255, 255);">
                                <use data-v-098d38e6="" xlink:href="#svg-chevron-right"
                                    style="color: rgb(255, 255, 255);"></use>
                            </svg></div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>
<script>
import { filterAndSortApps } from '@/assets/vendor/js/utail';
export default {
    data() {
        return {
            appData: [],
            top4: []
        }
    },
    props: ['category'],
    async created() {
        this.appData = this.$store.state.appData;
        this.filteredApps = filterAndSortApps(this.appData, this.category); // 传入 appData 和 category
        this.top4 = this.filteredApps.slice(0, 4);
    }
}
</script>
<style scoped lang="scss">
.free-container {
    width: 100%
}

.free-container .category-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 60px;
    padding-top: 30px;
    flex-direction: row;
    align-items: center
}

.free-container .category-list .category-item {
    flex: 0 0 calc(25% - 45px)
}

@media screen and (max-width:1200px) {
    .free-container .category-list {
        gap: 30px
    }

    .free-container .category-list .category-item {
        flex: 0 0 calc(33.33333% - 20px)
    }
}

@media screen and (max-width:768px) {
    .free-container .category-list {
        gap: 20px
    }

    .free-container .category-list .category-item {
        flex: 0 0 calc(50% - 10px)
    }
}

@media screen and (max-width:400px) {
    .free-container .category-list {
        gap: 20px
    }

    .free-container .category-list .category-item {
        flex: 0 0 100%
    }
}

.category-title {
    width: 100%;
    height: 36px;
    display: flex;
    justify-content: center;
    justify-content: space-between
}

.category-title .category-title-info {
    color: #262833;
    font-size: 30px;
    line-height: 36px;
    font-weight: 600
}

.category-title .learn-more-btn {
    width: 160px;
    height: 36px
}


.tmpl-container {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid #eaeaea
}

.tmpl-container:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -2px rgba(0, 0, 0, .1)
}

.tmpl-container .product-img {
    width: 100%;
    background-color: #f2f2f2;
    overflow: hidden;
    position: relative;
    padding: 20px 20px 78.7%
}

.tmpl-container .product-img .img {
    border-radius: 20px;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    position: absolute;
    overflow: hidden
}

.tmpl-container .info-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px 8px
}

.tmpl-container .info-container .info {
    display: flex;
    flex-direction: column;
    justify-content: center
}

.tmpl-container .info-container .info .info-name {
    font-size: 20px;
    font-weight: 700;
    color: #262833;
    max-width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

@media screen and (max-width:768px) {
    .tmpl-container .info-container .info .info-name {
        max-width: 100px
    }
}

.tmpl-container .info-container .info .category-rating-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px
}

.tmpl-container .info-container .info .category-rating-container .info-category {
    font-size: 14px;
    font-weight: 400;
    color: #515151
}

@media screen and (max-width:1000px) {
    .tmpl-container .info-container .info .category-rating-container {
        gap: 6px;
        font-size: 12px
    }
}

.tmpl-container .info-container .info-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    background-color: #515151;
    border-radius: 50%
}

.rating-container {
    display: flex;
    align-items: center
}

.rating-container .rating-score {
    margin-left: 4px;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    vertical-align: middle;
    display: inline-block
}
</style>