<template>
    <svg v-for="n in Math.floor(item.star)" :key="'full-' + n" aria-hidden="true" class="star-item svg-loader"
        style="width: 14px; height: 14px; fill: rgb(37, 41, 51);">
        <use xlink:href="#svg-full-star" style="color: rgb(37, 41, 51);"></use>
    </svg>
    <svg v-if="item.star % 1 > 0" aria-hidden="true" class="star-item svg-loader"
        style="width: 14px; height: 14px; fill: rgb(37, 41, 51);">
        <use xlink:href="#svg-half-star" style="color: rgb(37, 41, 51);"></use>
    </svg>
    <span class="rating-score" style="color:black;">{{ item.score }}</span>
</template>

<script>
export default{
    props:['item']
}
</script>