<template>
    <div class="category-rec-container" style="height: auto !important;">
        <div class="category-rec-list" >
            <div class="product-item" v-for="(app, index) in top8" :key="index">
                <a :href="'/d/'+app.title" class="tmpl-container t-image">
                    <div class="product-img"><img :src="app.img" class="img" lazy="loaded"></div>
                    <div class="info-container">
                        <div class="info">
                            <h3 class="info-name">{{ app.name }}</h3>
                            <div class="category-rating-container"><span class="info-category">{{ crc }}</span>
                                <div class="rating-container">

                                    <svg v-for="n in Math.floor(app.star)" :key="'full-' + n" aria-hidden="true"
                                        class="star-item svg-loader"
                                        style="width: 14px; height: 14px; fill: rgb(37, 41, 51);">
                                        <use xlink:href="#svg-full-star" style="color: rgb(37, 41, 51);"></use>
                                    </svg>
                                    <svg v-if="app.star % 1 > 0" aria-hidden="true" class="star-item svg-loader"
                                        style="width: 14px; height: 14px; fill: rgb(37, 41, 51);">
                                        <use xlink:href="#svg-half-star" style="color: rgb(37, 41, 51);"></use>
                                    </svg>
                                    <span class="rating-score" style="color:black;">{{ app.score }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="info-button"><svg aria-hidden="true" class="svg-loader"
                                style="width: 30px; height: 30px; fill: rgb(255, 255, 255);">
                                <use xlink:href="#svg-chevron-right" style="color: rgb(255, 255, 255);"></use>
                            </svg></div>
                    </div>
                </a>
            </div>
        </div>
        <MainAd className="my-4" />
    </div>
</template>

<script>
import { categoryForData } from '@/assets/vendor/js/utail'; // 导入外部的函数
import MainAd from '@/components/MainAd.vue';

export default {
    data() {
        return {
            appData: [
                
            ],
            top8: [],
            filteredApps: [] // 用于存储过滤后的应用数据
        };
    },
    components:{
        MainAd
    },
    mounted() {
    },
    async created() {
        this.filteredApps = categoryForData(this.$store.state.appData, "AI_Apps"); // 传入 appData 和 category
        this.top8 = this.filteredApps.slice(0, 8);

    }
};
</script>

<style lang="scss" scoped >
@media screen and (max-width: 1200px) {
    .category-container .category-rec-container .category-rec-list .product-item {
        width: calc(33.3% - 20px);
        flex: 0 0 calc(33.3% - 20px)
    }
}

@media screen and (max-width: 800px) {
    .category-container .category-rec-container .category-rec-list .product-item {
        width: calc(50% - 15px);
        flex: 0 0 calc(50% - 15px)
    }
}

.category-container .category-rec-container {
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px
}

.category-container .category-rec-container .category-rec-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 30px
}

.category-container .category-rec-container .category-rec-list .product-item {
    width: calc(25% - 23px);
    flex: 0 0 calc(25% - 23px)
}

@media screen and (max-width: 1200px) {
    .category-container .category-rec-container .category-rec-list .product-item {
        width: calc(33.3% - 20px);
        flex: 0 0 calc(33.3% - 20px)
    }
}

@media screen and (max-width: 800px) {
    .category-container .category-rec-container .category-rec-list .product-item {
        width: calc(50% - 15px);
        flex: 0 0 calc(50% - 15px)
    }
}

.tmpl-container {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid #eaeaea;
}

.tmpl-container .product-img {
    width: 100%;
    background-color: #f2f2f2;
    overflow: hidden;
    position: relative;
    padding: 20px 20px 78.7%;
}

.tmpl-container .product-img .img {
    border-radius: 20px;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    position: absolute;
    overflow: hidden;
}

.tmpl-container .info-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px 8px;
}

.tmpl-container .info-container .info {
    display: flex;
    flex-direction: column;
    justify-content: center
}

.tmpl-container .info-container .info .info-name {
    font-size: 20px;
    font-weight: 700;
    color: #262833;
    max-width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

@media screen and (max-width: 768px) {
    .tmpl-container .info-container .info .info-name {
        max-width: 100px
    }
}

.tmpl-container .info-container .info .category-rating-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px
}

.tmpl-container .info-container .info .category-rating-container .info-category {
    font-size: 14px;
    font-weight: 400;
    color: #515151
}

@media screen and (max-width: 1000px) {
    .tmpl-container .info-container .info .category-rating-container {
        gap: 6px;
        font-size: 12px
    }
}

.tmpl-container .info-container .info-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    background-color: #515151;
    border-radius: 50%
}

.rating-container {
    display: flex;
    align-items: center
}

.rating-container .rating-score {
    margin-left: 4px;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    vertical-align: middle;
    display: inline-block
}

.category-container .category-rec-container .category-rec-list .product-item{
  width:calc(25% - 23px);
  flex:0 0 calc(25% - 23px)
}
@media screen and (max-width:1200px){
.category-container .category-rec-container .category-rec-list .product-item{
    width:calc(33.3% - 20px);
    flex:0 0 calc(33.3% - 20px)
}
.tmpl-container .info-container .info-button {
    height: 15px;
    width: 15px;
}
}
@media screen and (max-width:800px){
.category-container .category-rec-container .category-rec-list .product-item{
    width:calc(50% - 15px);
    flex:0 0 calc(50% - 15px)
}
}
</style>