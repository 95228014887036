<template>
    <div class="editor-choice-main-container">
        <!---->
        <div class="editors-choice-container">
            <MainAd className="" />
            <TopRecList :data="topRecListData" />
            <MainAd className="my-2" />
            <div class="relevant-tools-container">
                <div class="relevant-tools-title">Relevant Tools</div>
                <GameList v-if="gameListData.length > 0" :data="gameListData" />
            </div>
            <div class="game-list-container">
                <div class="relevant-tools-title">Relevant Tools</div>
                <GameListH5 :data="gameListData" />
            </div>
        </div>
    </div>
</template>

<script>
import TopRecList from '@/views/TopRecList.vue'
import GameList from '@/views/GameList.vue'
import GameListH5 from '@/views/GameListH5.vue'
import MainAd from '@/components/MainAd.vue';
import { randomFilterDatas } from '@/assets/vendor/js/utail'

// import { loadDataByField, getPagedData } from '@/assets/vendor/js/db';
export default {
    components: {
        TopRecList,
        GameList,
        GameListH5,
        MainAd
    },
    data() {
        return {
            topRecListData: [],
            gameListData: []
        }
    },
    computed: {
        appData() {
            return this.$store.state.appData;  // 从 Vuex 获取 appData
        }
    },
    async created() {
        console.log("vuex--appData",this.appData);
        
        this.topRecListData = randomFilterDatas(this.appData, 4);
        this.gameListData = randomFilterDatas(this.appData, 12);
        // const appData=await getPagedData(1,100);
        // console.log("EditorChoicce頁面數據", appData);
        // this.topRecListData = randomFilterDatas(appData, 4);
        // this.gameListData = randomFilterDatas(appData, 12);
        // console.log("topRecListData", this.gameListData);

    }
}
</script>

<style lang="scss" scoped>
.editors-choice-container {
    margin: 0 auto;
    padding: 20px;
    max-width: 1200px
}

@media screen and (max-width:1024px) {
    .editors-choice-container {
        padding: 0 20px
    }
}

.relevant-tools-container {
    margin: 0 auto;
    width: 100%
}

@media screen and (max-width:1200px) {
    .relevant-tools-container {
        display: none;
    }
}

.relevant-tools-container .relevant-tools-title {
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 30px;
    font-weight: 700
}


.game-list-container {
    width: 100%
}

.game-list-container .relevant-tools-title {
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-size: 18px;
    font-weight: 700
}

@media screen and (min-width:1200px) {
    .game-list-container {
        display: none;
    }
}
</style>