<template>
    <header class="base-header-container">
        <div class="header-left-container">
            <a href="/" class="logo-container">
                <img src="/img/logo/logo.png"
            </a>
            <div class="header-nav-container">
                <div class="header-nav-wrapper">
                    <div class="nav-list">
                        <a href="/top_charts" class="nav-item"><span>TOP CHARTS</span></a>
                        <a href="/ai_tools" class="nav-item"><span>HOT AI TOOLS</span></a>
                        <a href="/ai_apps" class="nav-item"><span>HOT AI APPS</span></a>
                        <a href="/editors_choice" class="nav-item"><span>EDITOR'S CHOICE</span></a>
                        <a href="/topic" class="nav-item"><span>TOPIC</span></a>
                    </div>
                </div>
            </div>
        </div>
        <a href="/" class="logo-container">
            <img src="/img/logo/logo.png">
        </a>
        <div class="header-menu-button"  @click="rmClick">
            <svg aria-hidden="true" class="svg-loader" style="width: 30px; height: 30px; fill: white;">
                <use xlink:href="#svg-menu" style="color: white;"></use>
            </svg>
        </div>
        <div ref="toggleElement" :class="{'right-menu':'right-menu','show':isShow,'hide':isHide}" >
            <a href="/top_charts" class="nav-item"><span class="item-title">TOP CHARTS</span></a>
            <a href="/ai_tools" class="nav-item"><span class="item-title">HOT AI TOOLS</span></a>
            <a href="/ai_apps" class="nav-item"><span class="item-title">HOT AI APPS</span></a>
            <a href="/editors_choice" class="nav-item"><span class="item-title">EDITOR'S CHOICE</span></a>
            <a href="/topic" class="nav-item"><span class="item-title">TOPIC</span></a>
        </div>
    </header>
</template>

<script>

export default {
    name: "webHeader",
    props: {
        msg: String
    },
    data(){
        return{
            isShow:false,
            isHide:true
        };
    },
    methods:{
        rmClick(){
            this.isHide=!this.isHide;
            this.isShow=!this.isShow;
            // console.log(this.isHide);
            
        }
    }
}
</script>

<style lang="scss" scoped>
.hide{display: none;}
.right-menu .show{display: flex;}

.base-header-container {
    display: flex;
    align-items: center;
    height: 60px;
    width: 100%;
    background-color: #d6d6d6
}

.base-header-container .header-left-container {
    display: flex
}

.base-header-container .header-left-container .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    width: 260px
}

.base-header-container .header-left-container .header-nav-container {
    height: 100%
}

/* adatper h5 */
.base-header-container .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #000
}

.base-header-container .header-menu-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 50px
}

.base-header-container .right-menu {
    // display: none;
    position: absolute;
    top: 50px;
    right: 0;
    width: 180px;
    z-index: 100;
    background-color: #d6d6d6
}

.base-header-container .right-menu.right-menu-show {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center
}

.base-header-container .right-menu .nav-item {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border-bottom: 2px solid #fff
}

.base-header-container .right-menu .nav-item .item-title {
    text-align: center;
    height: 36px;
    line-height: 36px;
    font-size: 16px;
    font-weight: 400
}


@media screen and (max-width:1200px) {
    .base-header-container .header-left-container {
        display: none;
    }

    .base-header-container .header-left-container .header-nav-container {
        display: none
    }
}

@media screen and (min-width:1200px) {
    .base-header-container .logo-container {
        display: none;
    }

    .base-header-container .header-menu-button {
        display: none;
    }
}



/*purgecss end ignore*/
/*purgecss start ignore*/
.header-nav-wrapper {
    height: 100%
}

.header-nav-wrapper .nav-list {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 20px
}

.header-nav-wrapper .nav-list .nav-item {
    padding: 0 20px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: #515151;
    font-size: 16px;
    font-weight: 400
}

.header-nav-wrapper .nav-list .nav-item span {
    white-space: nowrap
}

.header-nav-wrapper .nav-list .nav-item :hover {
    background-color: #c6c6c6
}


</style>