<template>
  <div class="category-container" style="height: auto !important;">
    <div class="ct-mg-top-20">
      <webBanner v-if="device === 'desktop'" />
      <WebBannerH5 v-else-if="device === 'mobile'" />
      <WebBannerH5 v-else-if="device === 'tablet'" />
    </div>
    <MainAd className="my-4" />
    <CategoryRecContainer1 />
    <HomeCategorySection category="Free" v-if="device === 'desktop'" />
    <HomeSectionH5 category="Free" v-if="device === 'mobile'|| device === 'tablet'" />
    <HomeCategorySection category="Other" v-if="device === 'desktop'" />
    <HomeSectionH5 category="Other" v-if="device === 'mobile'|| device === 'tablet'" />
    <HomeCategorySection category="Writing" v-if="device === 'desktop'" />
    <HomeSectionH5 category="Writing" v-if="device === 'mobile'|| device === 'tablet'" />
    <HomeCategorySection category="Design" v-if="device === 'desktop'" />
    <HomeSectionH5 category="Design" v-if="device === 'mobile'|| device === 'tablet'" />
    <HomeCategorySection category="Coding" v-if="device === 'desktop'" />
    <HomeSectionH5 category="Coding" v-if="device === 'mobile'|| device === 'tablet'" />
    <HomeCategorySection category="Chatbot" v-if="device === 'desktop'" />
    <HomeSectionH5 category="Chatbot" v-if="device === 'mobile'|| device === 'tablet'" />
  </div>
</template>

<script>
import webBanner from '@/components/webBanner.vue';
import WebBannerH5 from '@/components/WebBannerH5.vue';
import HomeCategorySection from './HomeCategorySection.vue';
import CategoryRecContainer1 from './CategoryRecContainer1.vue';
import MainAd from '@/components/MainAd.vue';
import HomeSectionH5 from './HomeCategorySectionH5.vue';

export default {
  data() {
    return {
      device: ''
    }
  },
  components: {
    webBanner,
    HomeCategorySection,
    CategoryRecContainer1,
    MainAd,
    WebBannerH5,
    HomeSectionH5
  },
  mounted() {
    this.updateDeviceType();
    window.addEventListener('resize', this.updateDeviceType);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateDeviceType);
  },
  methods: {
    updateDeviceType() {
      const width = window.innerWidth;
      if (width >= 1200) {
        this.device = 'desktop';
      } else if (width >= 768 && width < 1200) {
        this.device = 'tablet';
      } else {
        this.device = 'mobile';
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ct-mg-top-20 {
  margin-top: 20px;
}

.category-container {
  width: 100%
}

.category-container .breadcrumb {
  font-size: 16px;
  font-weight: 500;
  color: #262833;
  padding-bottom: 10px
}

.category-container .breadcrumb .breadcrumb-item {
  display: inline-block
}

.category-container .breadcrumb .breadcrumb-item.category-name {
  color: #d6d6d6
}

.category-container .breadcrumb .breadcrumb-split {
  padding: 0 10px
}

.category-container .category-rec-container {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px
}

.category-container .category-rec-container .category-rec-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 30px
}

.category-container .category-rec-container .category-rec-list .product-item {
  width: calc(25% - 23px);
  flex: 0 0 calc(25% - 23px)
}

@media screen and (max-width:1200px) {
  .category-container {
    padding: 30px;
  }

  .category-container .category-rec-container .category-rec-list .product-item {
    width: calc(33.3% - 20px);
    flex: 0 0 calc(33.3% - 20px)
  }
}

@media screen and (max-width:800px) {
  .category-container .category-rec-container .category-rec-list .product-item {
    width: calc(50% - 15px);
    flex: 0 0 calc(50% - 15px)
  }
}
</style>