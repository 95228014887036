<template>
    <div class="game-list">
        <a v-for="(item,index) in data" :key="index" :href="'/d/'+item.title" class="game-item t-image">
            <div class="product-img">
                <img
                    :src="item.img"
                    class="item-img"
                    :data-src="item.img"
                    lazy="loaded"></div>
            <div class="info-container">
                <h1 class="info-name">{{item.name}}</h1>
                <div class="info-category-rating"><span class="info-category">{{item.category}}</span>
                    <div class="rating-container">
                        <StarCom :item="item" />
                    </div>
                </div>
            </div>
        </a>
    </div>
</template>

<script>
import StarCom from '@/components/StarCom.vue';
export default {
    props: {
        data: {
            type: Array,
            default: () => []
        }
    },
    components: {
        StarCom
    },
    created() {
        console.log("进入gameList", this.data);
    }
}
</script>

<styel lang="scss" scoped>
.game-list {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    row-gap: 24px;
    -moz-column-gap: 80px;
    column-gap: 80px;
    padding-top: 30px
}

@media screen and (max-width:1000px) {
    .game-list {
        -moz-column-gap: 40px;
        column-gap: 40px
    }
}

.game-list .game-item {
    flex: 0 0 calc(33.33333% - 53.33333px);
    display: flex;
    gap: 10px;
    overflow: hidden;
    border: 1px solid #eaeaea
}

@media screen and (max-width:1000px) {
    .game-list .game-item {
        flex: 0 0 calc(33.33333% - 26.66667px)
    }
}

.game-list .game-item:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -2px rgba(0, 0, 0, .1)
}

.game-list .game-item .product-img {
    width: 100px;
    height: 100px;
    position: relative;
    background-color: grey;
    border: 1px solid #eaeaea
}

.game-list .game-item .product-img .img {
    width: 100%;
    height: 100%;
    position: absolute
}

.game-list .game-item .info-container {
    width: calc(100% - 120px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center
}

.game-list .game-item .info-container .info-name {
    position: relative;
    z-index: 10;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    vertical-align: top;
    font-weight: 700;
    color: #262833;
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.game-list .game-item .info-container .info-category-rating {
    display: flex;
    flex-direction: column
}

.game-list .game-item .info-container .info-category-rating .info-category {
    position: relative;
    font-size: 14px;
    color: #515151
}

.rating-container {
    display: flex;
    align-items: center
}

.rating-container .rating-score {
    margin-left: 4px;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    vertical-align: middle;
    display: inline-block
}
</styel>