import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'; 


async function initializeAppData() {
    await store.dispatch('loadAppData');  // 调用 Vuex Action 加载数据
}

initializeAppData().then(appData => {
    const app = createApp(App, { appData });
    app.use(router);
    app.use(store);
    app.mount('#app');
  });

// createApp(App).use(router).mount('#app')
