import { createStore } from 'vuex';

// import { deleteAllRecords, openDatabase, saveData, loadData, loadDataInBatches } from '@/assets/vendor/js/db';

const store = createStore({
  state: {
    appData: null,  // 用于存储应用数据
    loading: true   // 用于控制加载状态
  },
  mutations: {
    setAppData(state, data) {
      state.appData = data;
      state.loading = false;  // 加载完成后，设置 loading 为 false
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    }
  },
  actions: {
    async loadAppData({ commit }) {
      try {
        commit('setLoading', true);
        // await openDatabase();
        // deleteAllRecords();
        // let data = await loadData(); // 从 IndexedDB 加载数据
        // console.log("初始化之前的数据",data);
        // if (!data) {
        const response = await fetch('/data/app.json'); // 请求本地 JSON 文件
        let data = await response.json();
        // console.log("初始化之后的数据",data);
        // await loadDataInBatches(data); // 保存到 IndexedDB
        // }
        commit('setLoading', false);
        commit('setAppData', data);  // 将数据存储到 Vuex
      } catch (error) {
        console.error('Error loading app data:', error);
        commit('setLoading', false);
      }
    }
  }
});

export default store;
