<template>
    <div class="topic-main-container">
        <div class="topic-container">
            <div  v-if="device === 'desktop'" class="topic-title">TOPIC</div>
            <MainAd />
            <webBanner v-if="device === 'desktop'" />
            <WebBannerH5 v-if="device === 'mobile' || device === 'mobile'" />
            <MainAd />
            <div class="relevant-tools-container">
                <div class="relevant-tools-title">Relevant Tools</div>
                <GameList :data="topRecListData" />
            </div>
            <div class="game-list-container">
                <div class="relevant-tools-title">Relevant Tools</div>
                <GameListH5 :data="topRecListData" />
            </div>
        </div>
    </div>
</template>

<script>
import MainAd from '@/components/MainAd.vue'
import webBanner from '@/components/webBanner.vue';
import GameList from '@/views/GameList.vue'
import GameListH5 from '@/views/GameListH5.vue'
import { randomFilterDatas } from '@/assets/vendor/js/utail'
import WebBannerH5 from '@/components/WebBannerH5.vue';
export default {
    components: {
        MainAd,
        webBanner,
        GameList,
        WebBannerH5,
        GameListH5
    },
    data() {
        return {
            releVantData: [],
            device: ''
        }
    },
    mounted() {
        this.updateDeviceType();
        window.addEventListener('resize', this.updateDeviceType);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateDeviceType);
    },
    methods: {
        updateDeviceType() {
            const width = window.innerWidth;
            if (width >= 1200) {
                this.device = 'desktop';
            } else if (width >= 768 && width < 1200) {
                this.device = 'tablet';
            } else {
                this.device = 'mobile';
            }
        }
    },
    async created() {
        this.topRecListData = randomFilterDatas(this.$store.state.appData, 12);
    }
}
</script>

<style lang="scss" scoped>
.topic-main-container {
    margin: 0 auto;
    padding: 0;
    max-width: 1200px
}

.topic-container {
    margin: 0 auto;
    padding: 40px 20px;
    max-width: 1200px
}

.topic-container .topic-title {
    height: 40px;
    width: 100%;
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
}

.relevant-tools-container {
    margin: 0 auto;
    width: 100%
}

.relevant-tools-container .relevant-tools-title {
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 30px;
    font-weight: 700
}

.game-list-container {
    width: 100%
}

.game-list-container .relevant-tools-title {
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-size: 18px;
    font-weight: 700
}

@media screen and (min-width:1200px) {
    .game-list-container {
        display: none;
    }
}

@media screen and (max-width:1200px) {
    .relevant-tools-container {
        display: none;
    }
}
</style>