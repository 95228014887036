<template>
    <div class="game-list" >
        <a v-for="(item,index) in data" :key="index" :href="'/d/'+item.title" class="game-item t-image" >
            <div class="product-img" ><img
                    :src="item.img"
                    class="item-img" 
                    :data-src="item.img"
                    lazy="loaded"></div>
            <div class="info-container" >
                <h1 class="info-name" >{{ item.name }}</h1>
                <div class="info-category-rating" ><span class="info-category"
                        >{{item.category}}</span>
                    <div class="rating-container">
                        <StarCom :item="item" />
                    </div>
                </div>
            </div>
        </a>    
        </div>
</template>

<script>

import StarCom from '@/components/StarCom.vue';
export default{
    props:['data'],
    components:{
        StarCom
    }
}
</script>

<style scoped lang="scss">
.game-list {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
    padding-top: 20px
}

.game-list .game-item {
    flex: 0 0 calc(50% - 4px);
    display: flex;
    gap: 10px;
    overflow: hidden;
    border: 1px solid #eaeaea
}

@media screen and (max-width:400px) {
    .game-list .game-item {
        flex: 0 0 100%
    }
}

.game-list .game-item .product-img {
    width: 80px;
    height: 80px;
    position: relative;
    background-color: grey;
    border: 1px solid #eaeaea
}

.game-list .game-item .product-img .img {
    width: 100%;
    height: 100%;
    position: absolute
}

.game-list .game-item .info-container {
    width: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center
}

.game-list .game-item .info-container .info-name {
    position: relative;
    z-index: 10;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    vertical-align: top;
    font-weight: 700;
    color: #262833;
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

@media screen and (max-width:480px) {
    .game-list .game-item .info-container .info-name {
        font-size: 16px
    }
}

.game-list .game-item .info-container .info-category-rating {
    display: flex;
    flex-direction: column
}

.game-list .game-item .info-container .info-category-rating .info-category {
    position: relative;
    font-size: 14px;
    color: #515151
}

@media screen and (max-width:480px) {
    .game-list .game-item .info-container .info-category-rating .info-category {
        font-size: 12px
    }
}

/*purgecss end ignore*/
/*purgecss start ignore*/
.rating-container {
    display: flex;
    align-items: center
}

.rating-container .rating-score {
    margin-left: 4px;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    vertical-align: middle;
    display: inline-block
}
</style>