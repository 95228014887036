<template>
    <div   class="tmpl-container"><a  :href="'/d/'+randomData.title"
            class="t-image">
            <div  class="product-img"><img 
                    :src="randomData.img"
                    class="ai-img"
                    :data-src="randomData.img"
                    lazy="loaded"></div>
            <div  class="info-container">
                <div  class="info-info"><span  class="info-category">{{randomData.category}}</span>
                    <h3  class="info-name">{{randomData.name}}</h3>
                </div>
                <div  class="info-button"><a
                    :href="'/d/'+randomData.title" class="c-button-container t-image"
                        style="border-radius: 6px; font-size: 12px; background-color: rgb(0, 136, 255);">Open</a></div>
            </div>
        </a></div>
</template>
<script>
import { randomFilterData } from '@/assets/vendor/js/utail';
export default {
    data() {
        return {
            randomData: {}
        }
    },
    async created() {
        this.appData = this.$store.state.appData;
        this.randomData = randomFilterData(this.appData);
    }
}
</script>
<style lang="scss" scoped>
.tmpl-container {
                display: block;
                display: flex;
                flex-direction: column;
                width: 100%;
                background-color: #f2f2f2
            }

            .tmpl-container .product-img {
                width: 100%;
                background-color: grey;
                position: relative;
                padding-bottom: 58.6%;
                overflow: hidden
            }

            .tmpl-container .product-img .ai-img {
                width: 100%;
                height: 100%;
                position: absolute
            }

            .tmpl-container .info-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px
            }

            .tmpl-container .info-container .info-info {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start
            }

            .tmpl-container .info-container .info-info .info-category {
                font-size: 10px;
                color: #515151
            }

            .tmpl-container .info-container .info-info .info-name {
                font-size: 16px;
                font-weight: 600;
                color: #262833;
                max-width: 180px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis
            }

            .tmpl-container .info-container .info-button {
                height: 30px;
                width: 50px
            }
</style>