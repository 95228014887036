<template>
    <div class="top-charts-main-container" style="height: auto !important;">
        <!---->
        <div class="charts-index-container" style="height: auto !important;">
            <MainAd className="my-4" />
            <div class="chart-list">
                <div class="chart-item">
                    <span class="chart-list-title">Top AI Tools</span>
                    <div class="chart-item-list">
                        <div class="chart-item-item" v-for="(item, index) in AiTools" :key="index">
                            <span class="item-rank">{{ index + 1 }}</span>
                            <div class="product-img">
                                <img :src="item.img" class="item-img" :data-src="item.img" lazy="loaded">
                            </div>
                            <div class="info-container">
                                <h2 class="info-name">{{ item.name }}</h2>
                                <div class="info-category-rating"><span class="info-category">{{ item.category }}</span>
                                    <div class="rating-container">
                                        <svg v-for="n in Math.floor(item.star)" :key="'full-' + n" aria-hidden="true"
                                            class="star-item svg-loader"
                                            style="width: 14px; height: 14px; fill: rgb(37, 41, 51);">
                                            <use xlink:href="#svg-full-star" style="color: rgb(37, 41, 51);"></use>
                                        </svg>
                                        <svg v-if="item.star % 1 > 0" aria-hidden="true" class="star-item svg-loader"
                                            style="width: 14px; height: 14px; fill: rgb(37, 41, 51);">
                                            <use xlink:href="#svg-half-star" style="color: rgb(37, 41, 51);"></use>
                                        </svg>
                                        <span class="rating-score" style="color:black;">{{ item.score }}</span>
                                    </div>
                                </div>
                            </div> <a :href="'/d/' + item.title" class="item-btn t-image"> OPEN </a>
                        </div>
                    </div>
                </div>
                <div class="chart-item">
                    <span class="chart-list-title">Top AI Apps</span>
                    <div class="chart-item-list">
                        <div class="chart-item-item" v-for="(item, index) in AiApps" :key="index">
                            <span class="item-rank">{{ index + 1 }}</span>
                            <div class="product-img">
                                <img :src="item.img" class="item-img" :data-src="item.img" lazy="loaded">
                            </div>
                            <div class="info-container">
                                <h2 class="info-name">{{ item.name }}</h2>
                                <div class="info-category-rating"><span class="info-category">{{ item.category }}</span>
                                    <div class="rating-container">
                                        <svg v-for="n in Math.floor(item.star)" :key="'full-' + n" aria-hidden="true"
                                            class="star-item svg-loader"
                                            style="width: 14px; height: 14px; fill: rgb(37, 41, 51);">
                                            <use xlink:href="#svg-full-star" style="color: rgb(37, 41, 51);"></use>
                                        </svg>
                                        <svg v-if="item.star % 1 > 0" aria-hidden="true" class="star-item svg-loader"
                                            style="width: 14px; height: 14px; fill: rgb(37, 41, 51);">
                                            <use xlink:href="#svg-half-star" style="color: rgb(37, 41, 51);"></use>
                                        </svg>
                                        <span class="rating-score" style="color:black;">{{ item.score }}</span>
                                    </div>
                                </div>
                            </div> <a :href="'/d/' + item.title" class="item-btn t-image"> OPEN </a>
                        </div>
                    </div>
                </div>
                <div class="chart-item">
                    <span class="chart-list-title">Top New Tools</span>
                    <div class="chart-item-list">
                        <div class="chart-item-item" v-for="(item, index) in NewTools" :key="index">
                            <span class="item-rank">{{ index + 1 }}</span>
                            <div class="product-img">
                                <img :src="item.img" class="item-img" :data-src="item.img" lazy="loaded">
                            </div>
                            <div class="info-container">
                                <h2 class="info-name">{{ item.name }}</h2>
                                <div class="info-category-rating"><span class="info-category">{{ item.category }}</span>
                                    <div class="rating-container">
                                        <svg v-for="n in Math.floor(item.star)" :key="'full-' + n" aria-hidden="true"
                                            class="star-item svg-loader"
                                            style="width: 14px; height: 14px; fill: rgb(37, 41, 51);">
                                            <use xlink:href="#svg-full-star" style="color: rgb(37, 41, 51);"></use>
                                        </svg>
                                        <svg v-if="item.star % 1 > 0" aria-hidden="true" class="star-item svg-loader"
                                            style="width: 14px; height: 14px; fill: rgb(37, 41, 51);">
                                            <use xlink:href="#svg-half-star" style="color: rgb(37, 41, 51);"></use>
                                        </svg>
                                        <span class="rating-score" style="color:black;">{{ item.score }}</span>
                                    </div>
                                </div>
                            </div> <a :href="'/d/' + item.title" class="item-btn t-image"> OPEN </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="top-charts-index-container">
            <div class="chart-type-container">
                <div class="category-container" v-for="category in categories" :key="category.id"
                    :class="{ 'active': activeCategory === category.id }" @click="setActiveCategory(category.id)">
                    {{ category.name }}
                </div>
            </div>
            <div v-if="activeCategoryGames && activeCategoryGames.length > 0"
                v-for="(game, index) in activeCategoryGames" :key="index" class="game-list">
                <div class="game-item">
                    <span class="item-rank">{{ index + 1 }}</span>
                    <div class="item-img"><img
                            :src="game.img"
                            class="item-img"
                            data-src="game.img"
                            lazy="loaded"></div>
                    <div class="info-container">
                        <div class="info-name">
                            <h2>{{game.name}}</h2>
                        </div>
                        <div class="info-category"><span>{{game.category}}</span></div>
                    </div> <a :href="'/d/'+game.title" class="get-btn t-image"> GET </a>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { categoryForData } from '@/assets/vendor/js/utail';
import MainAd from '@/components/MainAd.vue';
export default {
    data() {
        return {
            AiTools: [],
            AiApps: [],
            NewTools: [],
            categories: [
                { id: 1, name: 'Top AI Tools' },
                { id: 2, name: 'Top AI Apps' },
                { id: 3, name: 'Top New Tools' }
            ],
            activeCategory: null,
            activeCategoryGames: [],
            activeCategoryName: ''
        }
    },
    methods: {
        setActiveCategory(categoryId) {
            this.activeCategory = categoryId;
            this.fetchGamesForCategory(categoryId);
        },
        fetchGamesForCategory(categoryId) {
            if (categoryId === 1) {
                this.activeCategoryGames = categoryForData(this.$store.state.appData, "AI_Tools");
                this.activeCategoryName = 'AI Tools';
            } else if (categoryId === 2) {
                this.activeCategoryGames = categoryForData(this.$store.state.appData, "AI_Apps");
                this.activeCategoryName = 'AI Apps';
            } else if (categoryId === 3) {
                this.activeCategoryGames = categoryForData(this.$store.state.appData, "New_Tools");
                this.activeCategoryName = 'New Tools';
            }
        }
    },
    components: {
        MainAd
    },
    async created() {
        this.AiTools = categoryForData(this.$store.state.appData, "AI_Tools");
        this.AiApps = categoryForData(this.$store.state.appData, "AI_Apps");
        this.NewTools = categoryForData(this.$store.state.appData, "New_Tools");
        this.setActiveCategory(1);
    }
}
</script>

<style lang="scss" scoped>
.top-charts-main-container {
    margin: 0 auto;
    padding: 0
}

/*purgecss end ignore*/
/*purgecss start ignore*/
.charts-index-container {
    margin: 0 auto;
    width: 100%
}

.charts-index-container .chart-list {
    width: 100%;
    overflow: hidden;
    display: flex;
    gap: 60px;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center
}

@media screen and (max-width:1000px) {
    .charts-index-container .chart-list {
        gap: 40px
    }
}

@media screen and (max-width:1200px) {
    .charts-index-container {
        display: none;
    }
}

.charts-index-container .chart-list .chart-item {
    flex: 0 0 calc(33.33333% - 40px)
}

@media screen and (max-width:1000px) {
    .charts-index-container .chart-list .chart-item {
        flex: 0 0 calc(33.33333% - 26.66667px)
    }
}

.charts-index-container .chart-list .chart-item .chart-list-title {
    width: 100%;
    font-size: 24px;
    font-weight: 700
}

.charts-index-container .chart-list .chart-item .chart-item-list {
    padding: 20px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    gap: 24px
}

.charts-index-container .chart-list .chart-item .chart-item-list .chart-item-item {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    gap: 4px
}

.charts-index-container .chart-list .chart-item .chart-item-list .chart-item-item .item-rank {
    width: 20px;
    font-size: 14px;
    font-weight: 700
}

.charts-index-container .chart-list .chart-item .chart-item-list .chart-item-item .product-img {
    border-radius: 12px;
    overflow: hidden;
    width: 90px;
    height: 90px
}

@media screen and (max-width:1000px) {
    .charts-index-container .chart-list .chart-item .chart-item-list .chart-item-item .product-img {
        width: 80px;
        height: 80px
    }
}

.charts-index-container .chart-list .chart-item .chart-item-list .chart-item-item .product-img .item-img {
    width: 100%;
    height: 100%
}

.charts-index-container .chart-list .chart-item .chart-item-list .chart-item-item .info-container {
    width: calc(100% - 200px);
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden
}

.charts-index-container .chart-list .chart-item .chart-item-list .chart-item-item .info-container .info-name {
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
    font-weight: 600
}

@media screen and (max-width:1200px) {
    .charts-index-container .chart-list .chart-item .chart-item-list .chart-item-item .info-container .info-name {
        max-width: 100px;
        font-size: 16px
    }
}

@media screen and (max-width:900px) {
    .charts-index-container .chart-list .chart-item .chart-item-list .chart-item-item .info-container .info-name {
        max-width: 80px;
        font-size: 14px
    }
}

.charts-index-container .chart-list .chart-item .chart-item-list .chart-item-item .info-container .info-category-rating .info-category {
    font-size: 12px
}

.charts-index-container .chart-list .chart-item .chart-item-list .chart-item-item .item-btn {
    width: 70px;
    text-align: center;
    font-size: 14px;
    height: 28px;
    line-height: 24px;
    border: 2px solid #1ce4ff;
    color: #1ce4ff;
    border-radius: 6px
}

@media screen and (max-width:1000px) {
    .charts-index-container .chart-list .chart-item .chart-item-list .chart-item-item .item-btn {
        font-size: 12px;
        width: 60px;
        height: 24px;
        line-height: 20px
    }
}

.rating-container {
    display: flex;
    align-items: center
}

.rating-container .rating-score {
    margin-left: 4px;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    vertical-align: middle;
    display: inline-block
}

.top-charts-index-container {
    margin: 0 auto;
    padding: 0 10px;
    max-width: 768px
}

@media screen and (min-width:1200px) {
    .top-charts-index-container {
        display: none;
    }
}

.top-charts-index-container .chart-type-container {
    height: 50px;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 700;
    line-height: 30px
}

.top-charts-index-container .chart-type-container .active {
    color: #00a173;
    border-bottom: 2px solid #00a173
}

.game-list {
    padding: 10px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 20px
}

.game-list .game-item {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%
}

.game-list .game-item .item-rank {
    width: 30px;
    font-size: 14px;
    font-weight: 700
}

.game-list .game-item .item-img {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    overflow: hidden;
    background-color: grey
}

.game-list .game-item .info-container {
    width: calc(100% - 230px);
    display: flex;
    flex-direction: column
}

.game-list .game-item .info-container .info-name {
    font-size: 14px;
    font-weight: 700
}

.game-list .game-item .info-container .info-name h2 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.game-list .game-item .info-container .info-category {
    font-size: 12px;
    color: #8f8f8f
}

.game-list .game-item .get-btn {
    width: 60px;
    height: 28px;
    border: 1px solid #00a173;
    color: #00a173;
    font-size: 14px;
    text-align: center;
    border-radius: 4px;
    line-height: 26px
}
</style>